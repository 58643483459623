<template>
  <v-container
    ><h4 class="mb-3 text-uppercase">{{ $t("myFavorites.title") }}</h4>
    <v-row v-if="articles.length > 0"
      ><v-col
        cols="6"
        sm="4"
        md="3"
        lg="2"
        v-for="article in articles"
        :key="article.id"
      >
        <ArticleCard
          :article="article"
          @refresh="reload"
          :confirmRemove="true"
        /> </v-col
    ></v-row>
    <div v-else-if="!loading" class="text-center my-6 font-weight-semibold">
      {{ $t("myFavorites.noArticles") }}
    </div>
  </v-container>
</template>
<script>
import CustomService from "@/service/customService";
import { mapGetters } from "vuex";

import ArticleCard from "@/components/article/ArticleCard.vue";
export default {
  name: "MyFavorites",
  components: { ArticleCard },
  data() {
    return {
      articles: [],
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      getSavedArticles: "custom/getSavedArticles",
      user: "cart/getUser"
    }),
    articlesIds() {
      return this.getSavedArticles(this.user.userId);
    }
  },
  methods: {
    async reload() {
      if (this.articlesIds.length > 0) {
        this.loading = true;
        await CustomService.getArticlesByIds(this.articlesIds)
          .then(data => {
            this.articles = data.articles;
          })
          .finally(() => {
            this.loading = false;
          });
      } else this.articles = [];
    }
  },
  async mounted() {
    await this.reload();
  }
};
</script>
