var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("h4", { staticClass: "mb-3 text-uppercase" }, [
        _vm._v(_vm._s(_vm.$t("myFavorites.title")))
      ]),
      _vm.articles.length > 0
        ? _c(
            "v-row",
            _vm._l(_vm.articles, function(article) {
              return _c(
                "v-col",
                {
                  key: article.id,
                  attrs: { cols: "6", sm: "4", md: "3", lg: "2" }
                },
                [
                  _c("ArticleCard", {
                    attrs: { article: article, confirmRemove: true },
                    on: { refresh: _vm.reload }
                  })
                ],
                1
              )
            }),
            1
          )
        : !_vm.loading
        ? _c("div", { staticClass: "text-center my-6 font-weight-semibold" }, [
            _vm._v(" " + _vm._s(_vm.$t("myFavorites.noArticles")) + " ")
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }